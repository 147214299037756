import { Elements } from '@stripe/react-stripe-js';
import type { Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ChevronDown } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';

import { PaddleCreditCard } from '@/components/gateways/paddle-credit-card';
import PagarmeCreditCard from '@/components/gateways/pagarme-credit-card';
import PayPal from '@/components/gateways/paypal';
import StripeCreditCard from '@/components/gateways/stripe-credit-card';
import GatewaysEnum from '@/enums/gateways-enum';
import { useAuth } from '@/hooks/use-auth';

type PaymentMethod = 'payPalExterno' | 'pagarMeCreditCard' | 'stripeCreditCard' | 'creditCardPaddle';

interface StripeProps {
  options: StripeElementsOptions;
  promise: Promise<Stripe | null>;
}

type TrialCreditCardProps = {
  plans: any[] | {};
  daysTrial?: number;
};

export default function TrialCreditCard({ plans, daysTrial = 7 }: TrialCreditCardProps) {
  const valuePlan = useRef('');
  const auth = useAuth();
  const { user } = auth;
  const [openedPaymentMethod, setOpenedPaymentMethod] = useState<PaymentMethod | ''>('');
  const [stripe, setStripe] = useState<StripeProps>();
  const { t } = useTranslation(['common', 'pages/trial/index', 'components/plans']);

  const getDropdownClass = (method: string) => {
    return `flex items-center justify-between rounded-xl border select-none ${openedPaymentMethod === method ? 'border-[#00FF00] bg-[#394148]' : 'border-[#fff]/[.10]'} bg-[#1F2427] p-4 py-6 transition-all duration-300`;
  };

  const togglePaymentMethod = (method: PaymentMethod) => {
    if (openedPaymentMethod === method) {
      setOpenedPaymentMethod('');
    } else {
      setOpenedPaymentMethod(method);
    }
  };

  const logout = () => {
    auth.logout();
  };

  useEffect(() => {
    if (openedPaymentMethod === 'stripeCreditCard') {
      const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_ENCRYPT_PUBLIC_KEY ?? '', { locale: 'auto' });
      const stripeOptions: StripeElementsOptions = { clientSecret: process.env.NEXT_PUBLIC_STRIPE_ENCRYPT_PRIVATE_KEY ?? '' };

      setStripe({ promise: stripePromise, options: stripeOptions });
    }
  }, [openedPaymentMethod]);

  function setFirstCreditCardPaymentMethod(gatewayId: any) {
    switch (gatewayId) {
      case GatewaysEnum.STRIPE:
        setOpenedPaymentMethod('stripeCreditCard');
        break;
      case GatewaysEnum.PAGARME:
        setOpenedPaymentMethod('pagarMeCreditCard');
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (plans && Array.isArray(plans) && plans.length > 0) {
      plans[0].gateways.forEach((gateway: any) => {
        if (gateway.payment_methods.includes('cc') && gateway.order === 1) {
          setFirstCreditCardPaymentMethod(gateway.gateway_id);
        }
      });

      valuePlan.current = `${plans[0].currency_rep}${plans[0].value}`;
    }
  }, [plans]);

  function calculatePricePlanPerMonth(frequency: any, value: any) {
    switch (frequency) {
      case 365:
        return (value / 12).toFixed(2);
      case 90:
        return (value / 3).toFixed(2);
      case 30:
        return value;
      default:
        return value;
    }
  }

  function getFrequencyPlanInMonths(frequency: any) {
    switch (frequency) {
      case 365:
        return `12 ${t('trial.one_week_free.months')}`;
      case 90:
        return `3 ${t('trial.one_week_free.months')}`;
      case 30:
        return `1 ${t('trial.one_week_free.month')}`;
      default:
        return frequency;
    }
  }

  function frequencyPlan(frequency: any) {
    switch (frequency) {
      case 365:
        return `1 ${t('trial.one_week_free.year')}`;
      default:
        return `${frequency} ${t('trial.one_week_free.days')}`;
    }
  }

  function translateNamePlanByFrequency(frequency: any) {
    switch (frequency) {
      case 30:
        return `${t('components/plans:single_monthly.name')}`.toLocaleLowerCase();
      case 365:
        return `${t('components/plans:single_annual.name')}`.toLocaleLowerCase();
      case 90:
        return `${t('components/plans:single_trimestral.name')}`.toLocaleLowerCase();
      default:
        return `${t('components/plans:single_weekly.name')}`.toLocaleLowerCase();
    }
  }

  return (
    <div className='flex items-center justify-center'>
      <div className='relative flex min-h-[600px] w-full max-w-[1400px] overflow-hidden rounded-xl bg-[#0E1113] px-5' id='form'>
        <div className='relative z-[1] space-y-4 py-[61px] pl-5 tablet:pl-12 xl:min-w-[700px] xl:max-w-[850px]'>
          <div className='mb-12'>
            <h3 className='mb-4 text-2xl font-bold leading-[110%] text-white'>
              <Trans values={{ days: daysTrial }}>{t('trial.one_week_free.title')}</Trans>
            </h3>
          </div>

          <div className='mt-10 flex flex-col justify-between rounded-md border border-zinc-800 px-8 py-5 xl:flex-row xl:items-center'>
            <div className='flex flex-col'>
              <p className='font-semibold uppercase text-[#AEF55C]'>{user?.name !== '' ? `${t('app_dashboard.hey')}, ${user?.name?.split('.')[0] ?? ''}.` : `${t('app_dashboard.hey')}`}</p>
              <p className='text-sm text-white'>
                {t('app_dashboard.youre_logged_as')} {user?.email}
              </p>
            </div>
            <button onClick={() => logout()} className='font-sm flex h-[50px] w-full max-w-[180px] items-center justify-center rounded-md bg-white font-bold uppercase text-black transition-opacity hover:opacity-80'>
              {t('app_dashboard.sidebar_button_logout')}
            </button>
          </div>

          {user?.trialSubDate || user?.customTrial ? (
            <div className='mt-10 flex flex-col justify-between rounded-md border border-zinc-800 px-8 py-5 xl:flex-row xl:items-center'>
              <p className='font-medium text-white/60'>
                <Trans components={{ br: <br />, a: <Link href='/pricing' className='font-bold text-white/60 underline' /> }}>{t('pages/trial/index:message_trial_already_used')}</Trans>
              </p>
            </div>
          ) : (
            <>
              <div className='flex w-full flex-col gap-4'>
                {plans &&
                  Array.isArray(plans) &&
                  plans[0] &&
                  plans[0].gateways.map((gateway: any, index: number) => {
                    const gatewayId = gateway.gateway_id;
                    /* CreditCardPaddle */
                    if (gatewayId === GatewaysEnum.PADDLE && gateway.payment_methods.includes('button_redirect')) {
                      return (
                        <div key={`${gatewayId}-${index}`} className='flex flex-col gap-4'>
                          <div role='button' aria-label='Credit Card Paddle' tabIndex={0} className={`${getDropdownClass('creditCardPaddle')}`} onClick={() => togglePaymentMethod('creditCardPaddle')}>
                            <div className='flex w-full select-none items-center justify-between'>
                              <div className='flex gap-5'>
                                <h3 className='text-xl font-bold leading-[110%] text-white'>{t('pages/trial/index:method_of_payment_credit_card')}</h3>
                                <Image draggable={false} src='/assets/images/prices/icon-flags.webp' className='hidden min-w-[200px] xl:flex' height={23} width={275} alt='Credit Card' />
                              </div>
                              <ChevronDown className={`text-white ${openedPaymentMethod === 'creditCardPaddle' ? 'rotate-180' : ''}`} />
                            </div>
                          </div>

                          {openedPaymentMethod === 'creditCardPaddle' && (
                            <div className='flex flex-col gap-5'>
                              <PaddleCreditCard gateway_id={GatewaysEnum.PADDLE} plan_id={plans[0].id} currency_id={plans[0].currency_id} isTrial={plans[0].is_trial} gatewayPlanId={gateway.gateway_plan_id} trialDays={daysTrial} valuePlan={valuePlan.current} frequencyPlan={translateNamePlanByFrequency(plans[0].frequency)} />
                            </div>
                          )}
                        </div>
                      );
                    }

                    /* PagarMeCreditCard */
                    if (gatewayId === GatewaysEnum.PAGARME && gateway.payment_methods.includes('cc')) {
                      return (
                        <div key={`${gatewayId}-${index}`} className='flex flex-col gap-4'>
                          <div role='button' aria-label='Credit Card Pagarme' tabIndex={0} className={`${getDropdownClass('pagarMeCreditCard')}`} onClick={() => togglePaymentMethod('pagarMeCreditCard')}>
                            <div className='flex w-full select-none items-center justify-between'>
                              <div className='flex gap-5'>
                                <h3 className='text-xl font-bold leading-[110%] text-white'>{t('pages/trial/index:method_of_payment_credit_card')}</h3>
                                <div className='flex gap-1 xl:flex'>
                                  <Image draggable={false} src='/assets/images/prices/icon-flags.webp' className='hidden min-w-[200px] xl:flex' height={23} width={275} alt='Credit Card' />
                                </div>
                              </div>
                              <ChevronDown className={`text-white ${openedPaymentMethod === 'pagarMeCreditCard' ? 'rotate-180' : ''}`} />
                            </div>
                          </div>

                          {openedPaymentMethod === 'pagarMeCreditCard' && (
                            <div className='flex flex-col gap-5'>
                              <PagarmeCreditCard gateway_id={GatewaysEnum.PAGARME} plan_id={plans[0].id} currency_id={plans[0].currency_id} isTrial={plans[0].is_trial} trialDays={daysTrial} valuePlan={valuePlan.current} frequencyPlan={translateNamePlanByFrequency(plans[0].frequency)} />
                            </div>
                          )}
                        </div>
                      );
                    }

                    /* StripeCreditCard */
                    if (gatewayId === GatewaysEnum.STRIPE && gateway.payment_methods.includes('cc')) {
                      return (
                        <div key={`${gatewayId}-${index}`} className='flex flex-col gap-4'>
                          <div role='button' aria-label='Credit Card Stripe' tabIndex={0} className={`${getDropdownClass('stripeCreditCard')}`} onClick={() => togglePaymentMethod('stripeCreditCard')}>
                            <div className='flex w-full select-none items-center justify-between'>
                              <div className='flex gap-5'>
                                <h3 className='text-xl font-bold leading-[110%] text-white'>{t('pages/trial/index:method_of_payment_credit_card')}</h3>
                                <div className='hidden gap-1 xl:flex'>
                                  <Image draggable={false} src='/assets/images/prices/icon-flags.webp' className='min-w-[200px]' height={23} width={275} alt='Logo PayPal' />
                                </div>
                              </div>
                              <ChevronDown className={`text-white ${openedPaymentMethod === 'stripeCreditCard' ? 'rotate-180' : ''}`} />
                            </div>
                          </div>

                          {openedPaymentMethod === 'stripeCreditCard' && (
                            <div className='flex flex-col gap-5'>
                              {stripe?.promise && stripe?.options && (
                                // @ts-ignore
                                <Elements
                                  stripe={stripe.promise}
                                  options={
                                    (stripe.options.clientSecret,
                                    {
                                      fonts: [
                                        {
                                          cssSrc: 'https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;700&display=swap',
                                        },
                                      ],
                                    })
                                  }
                                >
                                  <StripeCreditCard gateway_id={GatewaysEnum.STRIPE} plan_id={plans[0].id} currency_id={plans[0].currency_id} isTrial={plans[0].is_trial} trialDays={daysTrial} valuePlan={valuePlan.current} frequencyPlan={translateNamePlanByFrequency(plans[0].frequency)} />
                                </Elements>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    }

                    /* PayPalExterno */
                    if (gatewayId === GatewaysEnum.PAYPAL && gateway.payment_methods.includes('button_redirect')) {
                      return (
                        <div key={`${gatewayId}-${index}`} className='flex flex-col gap-4'>
                          <div role='button' aria-label='PayPal' tabIndex={0} className={`${getDropdownClass('payPalExterno')}`} onClick={() => togglePaymentMethod('payPalExterno')}>
                            <div className='flex w-full select-none items-center justify-between'>
                              <div className='flex gap-5'>
                                <div className='hidden w-[50px] gap-1 xl:flex'>
                                  <Image draggable={false} src='/assets/images/prices/icon-paypal.webp' className='min-w-[60px] rounded bg-white p-1' height={100} width={100} alt='Logo PayPal' />
                                </div>
                              </div>
                              <ChevronDown className={`text-white ${openedPaymentMethod === 'payPalExterno' ? 'rotate-180' : ''}`} />
                            </div>
                          </div>

                          {openedPaymentMethod === 'payPalExterno' && (
                            <div className='flex flex-col gap-5 rounded-xl border p-10'>
                              <div className='flex items-center gap-2'>
                                <PayPal currencyCode={plans[0].currency_code} gateways={plans[0].gateways} isTrial={plans[0].is_trial} trialDays={daysTrial} valuePlan={valuePlan.current} frequencyPlan={translateNamePlanByFrequency(plans[0].frequency)} />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    }

                    return null;
                  })}
              </div>

              <div className='mt-10 flex flex-col gap-4'>
                <div className='flex items-center gap-2'>
                  <div>
                    <svg width={14} height={14} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M3.11111 0C1.393 0 0 1.393 0 3.11112V10.8889C0 12.607 1.393 14 3.11111 14H10.8889C12.607 14 14 12.607 14 10.8889V3.11112C14 1.393 12.607 0 10.8889 0H3.11111ZM7 3.11112C7.42933 3.11112 7.77778 3.45957 7.77778 3.8889C7.77778 4.31824 7.42933 4.66668 7 4.66668C6.57067 4.66668 6.22222 4.31824 6.22222 3.8889C6.22222 3.45957 6.57067 3.11112 7 3.11112ZM7 5.44446C7.42933 5.44446 7.77778 5.79291 7.77778 6.22224V10.1111C7.77778 10.5405 7.42933 10.8889 7 10.8889C6.57067 10.8889 6.22222 10.5405 6.22222 10.1111V6.22224C6.22222 5.79291 6.57067 5.44446 7 5.44446Z' fill='#53535F' />
                    </svg>
                  </div>

                  <p className='text-left text-sm font-medium text-[#879BAB]'>
                    <Trans values={{ days: daysTrial }}>{t('trial.one_week_free.alert.title')}</Trans>
                  </p>
                </div>

                {Array.isArray(plans) && plans.length > 0 && (
                  <p className='text-left text-sm font-medium leading-[140%] text-[#879BAB]'>
                    <Trans values={{ currency_rep: plans[0].currency_rep, totalprice: plans[0].value, totalPricePerMonth: calculatePricePlanPerMonth(plans[0].frequency, plans[0].value), frequencyInMonths: getFrequencyPlanInMonths(plans[0].frequency), frequency: frequencyPlan(plans[0].frequency) }}>{t('trial.one_week_free.alert.description')}</Trans>
                  </p>
                )}
              </div>
            </>
          )}
        </div>
        <div className='hidden items-center justify-end ipad:flex'>
          <Image className='' src='/assets/images/trial/seven-days/background.jpeg' alt='Seed' width={729} height={773} />
        </div>
      </div>
    </div>
  );
}
