import 'react-toastify/dist/ReactToastify.css';

import { Dialog, Transition } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { Fragment, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import CloseIcon from '@/assets/svg/close-icon';
import EyeIcon from '@/assets/svg/eye-icon';
import Button from '@/common/button';
import { useAuth } from '@/hooks/use-auth';

const defaultValues = {
  login: '',
  password: '',
  recaptcha: '',
};

interface FormData {
  login: string;
  password: string;
  recaptcha: string;
}

const SignIn = ({ closeModalSingIn }: any) => {
  const auth = useAuth();
  const [rememberMe] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('common');

  const schema = yup.object().shape({
    login: yup.string().email(t('login.mensage_error_empty_email')).required(t('login.mensage_error_empty_email')),
    password: yup.string().required(t('login.mensage_error_empty_password')),
    recaptcha: yup.string().required(t('login.mensage_error_empty_captch')),
  });

  const closeModal = () => {
    setIsOpen(false);
    closeModalSingIn();
  };

  const {
    register,
    setError,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onBlur',
  });

  const onSubmit = (data: FormData) => {
    const { login, password, recaptcha } = data;
    setLoading(true);
    auth.login({ login, password, recaptcha, rememberMe }, () => {
      setError('login', {
        type: 'manual',
        message: t('login.mensage_error_user_does_not_exist'),
      });
    });
    setLoading(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-black/25' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 scale-95' enterTo='opacity-100 scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
              <Dialog.Panel className='w-full max-w-[530px] overflow-hidden rounded-xl border border-[#515E6C]/55 bg-[#1D212A] p-10 text-left align-middle shadow-xl transition-all'>
                <div className='flex items-center justify-between'>
                  <Dialog.Title as='h3' className='text-2xl font-semibold leading-6 text-white'>
                    {t('login.already_registered')}
                  </Dialog.Title>
                  <button onClick={closeModal} className='text-lg font-semibold text-white hover:text-gray-800'>
                    <CloseIcon />
                  </button>
                </div>

                <div className='mt-4'>
                  <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2.5'>
                    <div className='flex w-full items-center rounded-xl border border-[#879BAB] px-10 py-4'>
                      <input placeholder={t('login.placeholder.email')} type='string' {...register('login')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
                    </div>
                    {errors.login && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.login?.message}</span>}
                    <div className='flex w-full items-center rounded-xl border border-[#879BAB] px-10 py-4'>
                      <input placeholder={t('login.placeholder.password')} type={showPassword ? 'text' : 'password'} {...register('password')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
                      <span className='cursor-pointer text-[#515F6C] transition-all hover:opacity-70' onClick={() => setShowPassword(!showPassword)}>
                        <EyeIcon />
                      </span>
                    </div>
                    {errors.password && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors?.password?.message}</span>}

                    <div className='flex items-center gap-2'>
                      <input type='checkbox' id='emailCheckbox' className='form-checkbox size-3 rounded-sm border border-white bg-white text-green-600 checked:bg-green-600' />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor='emailCheckbox' className='cursor-pointer text-xs font-medium leading-[140%] text-white'>
                        {t('login.remember_email')}
                      </label>
                    </div>

                    <div className='mt-2 flex flex-col place-content-center gap-5'>
                      <div className='text-center'>
                        <div className='dispaly:inline-block'>
                          <ReCAPTCHA
                            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY ?? ''}
                            onChange={(token: string | null) => {
                              setValue('recaptcha', token ?? '');
                              clearErrors('recaptcha');
                            }}
                            onExpired={() => {
                              setValue('recaptcha', '');
                              setError('recaptcha', { type: 'required' });
                            }}
                          />
                        </div>
                      </div>
                      {errors.recaptcha && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors.recaptcha?.message}</span>}
                    </div>

                    <div>
                      <Button type='submit' className='py-3' variant='primary' fullWidth loading={loading}>
                        <span className='text-sm'>{t('login.button_sign_in')}</span>
                      </Button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SignIn;
