import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import { sendGTMEvent } from '@next/third-parties/google';
import classNames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import EyeIcon from '@/assets/svg/eye-icon';
import Button from '@/common/button';
import type { CreateUserTrialParams } from '@/context/types';
import { useAuth } from '@/hooks/use-auth';

import SignInModal from './one-week/sign-in';

const defaultValues = {
  email: '',
  repeatEmail: '',
  phone: '',
  password: '',
  passwordRepeat: '',
  recaptcha: '',
};
interface FormData {
  email: string;
  password: string;
  passwordRepeat: string;
  recaptcha: string;
}

interface CreateAccountProps {
  custom?: number | null;
  daysTrial?: number;
}

export default function CreateAccount({ custom, daysTrial = 7 }: CreateAccountProps) {
  const router = useRouter();
  const auth = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showMdalLogin, setModalLogin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation(['components/trial/create-account', 'common']);
  const schema = yup.object().shape({
    email: yup.string().email(t('common:login.mensage_error_valid_email')).required(t('common:login.mensage_error_empty_email')).max(255),
    repeatEmail: yup
      .string()
      .email(t('common:login.mensage_error_valid_email'))
      .required(t('common:login.mensage_error_empty_email'))
      .max(255)
      .oneOf([yup.ref('email')], t('common:login.mensage_error_repeat_email')),
    password: yup.string().required(t('common:login.mensage_error_empty_password')).min(6, t('common:login.mensage_error_short_password')),
    passwordRepeat: yup
      .string()
      .required(t('common:login.mensage_error_empty_password'))
      .min(6, t('common:login.mensage_error_short_password'))
      .oneOf([yup.ref('password')], t('common:login.mensage_error_repeat_password')),
    recaptcha: yup.string().required(t('common:login.mensage_error_empty_captch')),
  });
  const {
    register,
    setError,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onBlur',
  });

  const handleLogin = (data: FormData) => {
    auth.login({ login: data.email, password: data.password, recaptcha: data.recaptcha });
  };

  const onSubmit = async (data: FormData) => {
    const { email, password, passwordRepeat, recaptcha } = data;
    setIsLoading((prevState) => !prevState);
    try {
      const body: CreateUserTrialParams = {
        email,
        password,
        password_repeat: passwordRepeat,
        recaptcha,
        ref: null,
      };
      if (custom) {
        body.custom = custom;
      }

      await auth.createTrial(
        {
          ...body,
        },
        ({ response }) => {
          // @ts-ignore
          window?.grecaptcha?.reset();
          // @ts-ignore
          const errorMessages = response?.data?.message;
          toast.error(typeof errorMessages === 'string' ? errorMessages : 'An error occurred while creating the user. Please try again!', {
            position: 'top-center',
          });
          setIsLoading((prevState) => !prevState);
        },
        () => {
          handleLogin(data);
          sendGTMEvent({ event: 'createAccount' });
          reset();
          if (custom) {
            router.push('/thankyou');
          }
          setIsLoading((prevState) => !prevState);
        }
      );
    } catch (error) {
      toast.error('An error occurred while creating the user. Please try again!', {
        position: 'top-center',
      });
      setIsLoading((prevState) => !prevState);
    }
  };

  const modalLogin = () => {
    setModalLogin(true);
  };

  const closeModalSingIn = () => {
    setModalLogin(false);
  };
  return (
    <div className='flex items-center justify-center'>
      <div className='relative w-full max-w-[1400px] overflow-hidden rounded-xl bg-[#0E1113]' id='form'>
        <div className='relative z-[1] px-5 py-[61px] tablet:pl-12 tablet:pr-0 xl:w-1/2'>
          <div className='mb-5'>
            <h3 className='mb-4 text-2xl font-bold leading-[110%] text-white'>{t('trial.create_your_account')}</h3>
            <p className='text-sm font-medium leading-[140%] text-[#879BAB]'>
              <Trans components={{ br: <br /> }} i18nKey={t('trial.create_your_account_description')} values={{ days: daysTrial }} />
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2.5' autoComplete='off'>
            <div className={`flex w-full items-center gap-4 rounded-xl border ${errors?.email?.message ? 'border-[#FD0C34]' : 'border-[#879BAB]'} px-10 py-4`}>
              <input data-lpignore='true' placeholder={t('trial.input_email')} type='text' {...register('email')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
              {errors.email && <span className='ml-1 flex w-1/2 items-center text-xs font-medium tracking-wide text-red-500'>{errors?.email?.message}</span>}
            </div>
            <div className={`flex w-full items-center gap-4 rounded-xl border ${errors?.repeatEmail?.message ? 'border-[#FD0C34]' : 'border-[#879BAB]'} px-10 py-4`}>
              <input data-lpignore='true' placeholder={t('trial.input_repeat_email')} type='text' {...register('repeatEmail')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
              {errors.repeatEmail && <span className='ml-1 flex w-1/2 items-center text-xs font-medium tracking-wide text-red-500'>{errors?.repeatEmail?.message}</span>}
            </div>
            <div className={`flex w-full items-center gap-4 rounded-xl border ${errors?.password?.message ? 'border-[#FD0C34]' : 'border-[#879BAB]'} px-10 py-4`}>
              <div className='flex w-full gap-1'>
                <input data-lpignore='true' placeholder={t('trial.input_password')} type={showPassword ? 'text' : 'password'} {...register('password')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
                <span className='cursor-pointer text-[#515F6C] transition-all hover:opacity-70' onClick={() => setShowPassword(!showPassword)}>
                  <EyeIcon />
                </span>
              </div>
              {errors.password && <span className='ml-1 flex w-1/2 items-center text-xs font-medium tracking-wide text-red-500'>{errors?.password?.message}</span>}
            </div>
            <div className={`flex w-full items-center gap-4 rounded-xl border ${errors?.passwordRepeat?.message ? 'border-[#FD0C34]' : 'border-[#879BAB]'} px-10 py-4`}>
              <div className='flex w-full gap-1'>
                <input data-lpignore='true' placeholder={t('trial.input_confirm_password')} type={showPassword ? 'text' : 'password'} {...register('passwordRepeat')} className={classNames('w-full bg-transparent text-sm text-[#D6E4EF] outline-0 placeholder:font-bold placeholder:uppercase focus:outline-0')} />
                <span className='cursor-pointer text-[#515F6C] transition-all hover:opacity-70' onClick={() => setShowPassword(!showPassword)}>
                  <EyeIcon />
                </span>
              </div>
              {errors.passwordRepeat && <span className='ml-1 flex w-1/2 items-center text-xs font-medium tracking-wide text-red-500'>{errors?.passwordRepeat?.message}</span>}
            </div>
            <div className='mt-2 flex flex-col place-content-center gap-5'>
              <div className='text-center'>
                <div className='dispaly:inline-block'>
                  <ReCAPTCHA
                    sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY ?? ''}
                    onChange={(token: string | null) => {
                      setValue('recaptcha', token ?? '');
                      clearErrors('recaptcha');
                    }}
                    onExpired={() => {
                      setValue('recaptcha', '');
                      setError('recaptcha', { type: 'required' });
                    }}
                  />
                </div>
              </div>
              {errors.recaptcha && <span className='ml-1 flex items-center text-xs font-medium tracking-wide text-red-500'>{errors.recaptcha?.message}</span>}
            </div>
            <div className='flex flex-col gap-4'>
              <Button type='submit' id='click_obtenha_sete_dias_gratuitos' className='!bg-[#AEF55C] py-3 transition-opacity hover:opacity-80' fullWidth loading={isLoading}>
                {isLoading ? t('trial.buttons.submitting') : t('trial.buttons.sign_up', { days: daysTrial })}
              </Button>

              <Button type='button' className={`w-full rounded-xl bg-[#363D43] py-4 text-center transition-opacity hover:opacity-80 mobile:px-10 ${isLoading ? '!cursor-not-allowed !opacity-80' : ''}`} variant='gray' fullWidth disabled={isLoading} onClick={() => modalLogin()}>
                {t('trial.buttons.sign_in')}
              </Button>
            </div>
          </form>
          {showMdalLogin && <SignInModal closeModalSingIn={closeModalSingIn} />}
        </div>
        <div className='absolute -bottom-5 right-0 hidden h-full w-1/2 items-end justify-end laptop:flex'>
          <Image className='object-fit size-full h-auto select-none' src='/assets/images/trial/seven-days/background.png' alt='Seed' width={1344} height={1406} />
        </div>
      </div>
    </div>
  );
}
